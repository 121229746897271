/* src/index.css */

/* check also font: Montserrat */
/* check also font: Fredoka */
/* check also font: Open Sans */

/* Importing Poppins and Roboto via Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* My Classes for Cards etc */
@import './MyStyles.css';   





/* Define the Jameel Noori Nastaleeq font */
@font-face {
  font-family: 'Jameel Noori Nastaleeq';
  /* src: url('/fonts/jameel-noori-nastaleeq.ttf') format('truetype'); */
  /* src: url('/jameel-noori-nastaleeq.ttf') format('truetype'); */
  src: url('./Assets/Fonts/jameel-noori-nastaleeq.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

/* Set the default font for the body */
/* body {
  font-family: 'Poppins', sans-serif;
} */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Additional classes to handle other fonts */
.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-jameel {
  font-family: 'Jameel Noori Nastaleeq', sans-serif;
}
